<template>
  <div>
    <h1>Rekli su o nama</h1>
    <div id="comments">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="bg-info"
        img-width="1024"
        img-height="280"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide caption="Jelena K" img-blank img-alt="Blank image">
          <p>
            Najbolje dermatoveneroloske i usluge estetske medicine na jednom
            mestu! Najbolja i najstrucnija doktorka.
          </p>
        </b-carousel-slide>
        <b-carousel-slide
          caption="Bojana Drcalic"
          img-blank
          img-alt="Blank image"
        >
          <p>
            Sve preporuke za najbolju doktorku!!!Prezadovoljna sam
            uslugama,prijatan ambijent i osoblje.
          </p>
        </b-carousel-slide>
        <b-carousel-slide
          caption="Dragana Novakovic"
          img-blank
          img-alt="Blank image"
        >
          <p>Resenje za sve vase kozne probleme. Najbolja doktorka ❤</p>
        </b-carousel-slide>
        <b-carousel-slide
          caption="Anđela Ćirović"
          img-blank
          img-alt="Blank image"
        >
          <p>
            Sve pohvale za osoblje, jako su ljubazni i profesionalni. Odličan
            ambijent, sjajne usluge. Izuzetno sam zadovoljna. Topla preporuka...
          </p>
        </b-carousel-slide>
        <b-carousel-slide
          caption="Isidora Špica"
          img-blank
          img-alt="Blank image"
        >
          <p>
            Sve pohvale za profesionalnost, prijatnu atmosferu i divno osoblje😊
          </p>
        </b-carousel-slide>
        <b-carousel-slide
          caption="Rada Ćirović"
          img-blank
          img-alt="Blank image"
        >
          <p>
            Veoma sam zadovoljna. Usluga na vrhunskom nivou. Sve preporuke👍💯
          </p></b-carousel-slide
        >
      </b-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style></style>
