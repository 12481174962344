<template>
  <div id="app">
    <Header />
    <router-view />

    <Footer />
    <ScrollUp />
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import ScrollUp from "vue-scroll-up";
export default {
  components: {
    Header,
    Footer,
    ScrollUp,
  },
};
</script>
<style scooped lang="scss">
@import "./assets/styles/index.scss";
@import "~bootstrap/scss/bootstrap";

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100%;
}
</style>
