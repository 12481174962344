<template>
  <div class="main">
    <h1>Sertifikati</h1>
    <ul class="cards">
      <li
        class="cards_item"
        v-for="item in certificates.slice().reverse()"
        :key="item.id"
      >
        <div class="card1">
          <div class="card_image">
            <img :src="item.url" :alt="item.title" />
          </div>
          <div class="card_content">
            <h2 class="card_title">{{ item.title }}</h2>
            <p class="card_text">
              {{ item.text }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      certificates: [
        {
          id: 1,
          title: "Eadv",
          text: "European academy of Dermatology and venerology",
          url: require("@/assets/images/certificates/edave_paris_france.jpg"),
        },
        {
          id: 2,
          title: "Eadv",
          text: "European academy of Dermatology and venerology",
          url: require("@/assets/images/certificates/eadv-2019-madrid.png"),
        },
        {
          id: 3,
          title: "Nulem",
          text: "NACIONALNO UDRUŽENJE LEKARA ESTETSKE MEDICINE",
          url: require("@/assets/images/certificates/nulem.jpg"),
        },
        {
          id: 4,
          title: "Msf pharm",
          text: "Visage Excellence Methods and obtained proper skills of using Aptos product",
          url: require("@/assets/images/certificates/msfpharm.png"),
        },
        {
          id: 5,
          title: "Medica Aesthetica",
          text: "Attended workshop and fully trained on basic application on hyaluronic acid fillers",
          url: require("@/assets/images/certificates/medica-aeshtetica.jpg"),
        },
        {
          id: 6,
          title: "Promoitalia",
          text: "Promoitalia's educational workshop on the NUCLEOFILL on Promoitalia and VM corporation product ",
          url: require("@/assets/images/certificates/promoitalia.png"),
        },
        {
          id: 7,
          title: "Eterna ",
          text: "Program kontinuirane medicinske edukacije, primena neuromodulatora u medicini",
          url: require("@/assets/images/certificates/eterna.png"),
        },
        {
          id: 8,
          title: "Lilly ",
          text: "Praktična dermoskopija napredni kurs , kontinuirana edukacija nacionalni seminar 1.kategorije",
          url: require("@/assets/images/certificates/lilly.jpg"),
        },
        {
          id: 9,
          title: "Medica Aesthetica",
          text: "Education traning by MD PhD Alain-Ali Mojallal, thenologys in syntesis of hyaluronic fillers",
          url: require("@/assets/images/certificates/medica-aeshtetica.jpg"),
        },
        {
          id: 10,
          title: "Nulem",
          text: "Botulinumski toksin - primena u estetskoj medicini",
          url: require("@/assets/images/certificates/nulem.jpg"),
        },
        {
          id: 11,
          title: "Medica Aesthetica",
          text: "Masterclass for hyperpigmentation given by PhD Fernando Bouffard Fita",
          url: require("@/assets/images/certificates/medica-aeshtetica.jpg"),
        },
        {
          id: 12,
          title: "Institute",
          text: "Succesfully participated and completed the course in Aesthetic Mesotherapy",
          url: require("@/assets/images/certificates/institute-bcn.png"),
        },
        {
          id: 13,
          title: "Nulem",
          text: "Tehnika nadoknade volumena u cilju ulklanjanja znaka starenja",
          url: require("@/assets/images/certificates/nulem.jpg"),
        },
        {
          id: 14,
          title: "Derma pen world",
          text: "Attended & Participated in dermapen world's traning program",
          url: require("@/assets/images/certificates/dermapen.jpg"),
        },
        {
          id: 15,
          title: " Nithya s-line",
          text: "Praktična primena Nithya s-sline proizvoda",
          url: require("@/assets/images/certificates/nithya1.jpg"),
        },
      ],
    };
  },
};
</script>

<style></style>
