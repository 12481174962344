<template>
  <div>
    <b-navbar-brand to="/" class="logo">
      <span>Laser centar</span> <br />
      <h5>Skincare82</h5>
    </b-navbar-brand>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
