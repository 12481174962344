<template>
  <b-navbar
    toggleable="lg"
    class="bg- px-3 px-md-0 w-100"
    v-on-clickaway="clickedAway"
  >
    <b-container fluid>
      <logo />
      <b-navbar-toggle target="nav-collapse" class="border-0">
        <span class="when-opened">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </span>
        <span class="when-closed">
          <font-awesome-icon icon="fa-solid fa-bars" />
        </span>
      </b-navbar-toggle>

      <b-collapse ref="nav-collapse" id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-dropdown-item to="/">Početna</b-dropdown-item>
          <b-dropdown-item to="/o-nama">O nama</b-dropdown-item>
          <b-dropdown-item to="/usluge">Usluge</b-dropdown-item>
          <b-dropdown-item to="/galerija">Galerija</b-dropdown-item>
          <b-dropdown-item to="/cenovnik">Cenovnik</b-dropdown-item>
          <b-dropdown-item to="/kontakt">Kontakt</b-dropdown-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import Logo from "../layout/Logo.vue";

export default {
  name: "home",
  components: {
    Logo,
  },

  mixins: [clickaway],
  methods: {
    clickedAway() {
      if (this.$refs["nav-collapse"].show)
        this.$root.$emit("bv::toggle::collapse", "nav-collapse");
    },
  },
};
</script>
