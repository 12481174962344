<template>
  <div>
    <b-modal id="modal-xl" size="xl" title="Novo u ponudi">
      <p class="title-img">DERMAPEN 4D</p>
      <img src="../assets/images/dermapen2.jpg" alt="dermapen" />
      <!-- <img src="../assets/images/dermapen1.jpg" alt="dermapen" /> -->
    </b-modal>
    <b-carousel
      id="banner"
      :interval="4000"
      controls
      indicators
      label-next=""
      label-prev=""
      class="intro"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        caption="Dobro došli"
        text="Skincare82 laser centar Priboj"
      >
        <my-button v-b-modal.modal-xl>Novo u ponudi</my-button>
        <!-- <b-button  variant="primary">xl modal</b-button> -->
        <template #img><div class="img-container first"></div></template
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Dermatologija"
        text="Koža kao najveći organ ljudskog tela ima višestruku funkciju. "
      >
        <router-link to="/usluge/dermatologija"
          ><my-button>Saznaj više</my-button></router-link
        >
        <template #img> <div class="img-container second"></div></template
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Estetski tretmani"
        text="Estetska dermatologija je oblast medicine koja se bavi ulepšavanjem izgleda kože i podkožnog tkiva."
      >
        <router-link to="/usluge/hirurske-intervencije"
          ><my-button>Saznaj više</my-button></router-link
        >
        <template #img> <div class="img-container third"></div></template
      ></b-carousel-slide>
    </b-carousel>
    <Scrollama :debug="false" :offset="1" @step-enter="handler">
      <b-container class="step"> <about-us /> </b-container>
      <b-container class="step"> <services /> </b-container>
      <b-container class="step"> <comments /> </b-container>
      <b-container class="step"> <certificate /> </b-container>
    </Scrollama>
  </div>
</template>

<script>
import AboutUs from "../components/home/AboutUs.vue";
import Comments from "../components/home/Comments.vue";
import Services from "../views/services/Services.vue";
import MyButton from "../components/layout/MyButton.vue";
// import "intersection-observer"; // for cross-browser support
import Scrollama from "vue-scrollama";
import Certificate from "../components/home/Certificate.vue";

export default {
  name: "Home",
  components: {
    AboutUs,
    Comments,
    Services,
    MyButton,
    Scrollama,
    Certificate,
  },

  methods: {
    handler({ element, index, direction }) {
      console.log(index);
      if (direction === "down" && !element.classList.contains("active"))
        element.classList.add("active");
    },
  },
};
</script>

<style>
.modal {
  top: 10% !important;
}
.modal-footer {
  display: none !important;
}

.title-img {
  color: black;
  font-size: x-large;
  font-weight: 800;
}
img {
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
}
</style>
