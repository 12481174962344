<template>
  <div class="container center">
    <transition name="slide-in">
      <div class="card">
        <div class="imgBx">
          <img src="@/assets/images/laser.jpg" alt="drMaja" />
        </div>
        <div class="contentBx">
          <div class="content">
            <h2>Dermatološki centar</h2>
            <span>Skincare82</span>
            <p class="pt-4">
              Dermatološki centar Skincare82 je prvi privatni centar za kožne,
              polne bolesti i kozmetologiju u Priboju.
            </p>
            <router-link to="/o-nama"
              ><my-button class="btn-brand-dark"
                >Saznaj više</my-button
              ></router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <div class="card">
      <div class="imgBx">
        <img src="@/assets/images/drMajaprofil.jpeg" alt="drMaja" />
      </div>
      <div class="contentBx text-center">
        <div class="content">
          <h2>Biografija</h2>
          <span>dr Marija Stanišić</span>
          <p>Dermatovenerolog i sertifikovani lekar estetske medicine</p>
          <router-link to="/o-nama">
            <my-button class="btn-brand-dark"
              >Saznaj više</my-button
            ></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "../layout/MyButton.vue";

export default {
  components: { MyButton },
  methods: {
    moveUp() {
      window.scrollTo(0, 0);
    },
    visibilityChanged(isVisible, element) {
      if (isVisible == true) {
        element.target.classList.add("slide-top");
      }
    },
  },
};
</script>

<style></style>
