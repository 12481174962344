<template>
  <button class="btn btn-border">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "MyButton",
};
</script>

<style scooped lang="scss"></style>
