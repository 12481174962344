import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
export { default as MyButton } from "../components/layout/MyButton.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/o-nama",
    name: "O name",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/usluge",
    name: "Usluge",
    component: () => import("../views/services/Services.vue"),
  },
  {
    path: "/usluge/dermatologija",
    name: "dermatologija",
    component: () => import("../views/services/Dermatologija.vue"),
  },
  {
    path: "/usluge/hirurske-intervencije",
    name: "hirurske-intervencije",
    component: () => import("../views/services/HirurskeIntervencije.vue"),
  },
  {
    path: "/galerija",
    name: "Galerija",
    component: () => import("../views/Galery.vue"),
  },
  {
    path: "/cenovnik",
    name: "Cenovnik",
    component: () => import("../views/Prices.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () => import("../views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    }
    if (to.hash) {
      return {
        el: to.hash + 50,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
